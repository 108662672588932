import styled from 'styled-components';

export const Overlay = styled.div`
  position: absolute !important;
  width: 85%;
  max-height: 64vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 350px) {
    width: 80%;
  }

  svg {
    opacity: 0.75;
    max-height: 64vh;
  }
`;
