import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import api from 'shared/api';
import { formatDate } from 'shared/helpers';
import { Button, ContentLoader, Notice } from 'shared/components';
import { CardRow } from 'Profile/components';
import * as Styled from './styles';

const KycStep2 = ({
  idToken,
}) => {
  const history = useHistory();
  const user = useSelector(state => get(state, 'account.user'));
  const userID = get(user, 'user_id');

  const [isLoading, setLoading] = useState(true);
  const [idCardTextData, setIdCardTextData] = useState(null);

  useEffect(() => {
    api.post('/api/kyc/extract-text', { user_id: userID, id_card_key: idToken })
      .then((res) => {
        const textData = get(res, 'data');
        setIdCardTextData(textData);
        setLoading(false);
      })
      .catch(() => {
        toast.error('KYC text extraction from ID has failed');
        setLoading(false);
      });
  }, []);

  if (isLoading) {
    return (
      <div className="KycStep2">
        <ContentLoader />
      </div>
    );
  }

  const handleConfirm = () => {
    history.push('/');
  };

  const getFullName = () => {
    const firstName = get(idCardTextData, 'first_name');
    const lastName = get(idCardTextData, 'last_name');

    if (!firstName && !lastName) { return 'Danilla Riyadi'; }

    return `${firstName} ${lastName}`;
  };

  return (
    <div className="KycStep2">
      {!idCardTextData && (
        <Notice
          type="warning"
          title="Invalid ID card was used for validation"
          description="Please contact the bank to receive your data"
        />
      )}
      <Styled.KycCard>
        <Styled.CardHeading>Personal details</Styled.CardHeading>
        <CardRow label="NIK" value={get(idCardTextData, 'national_id_nr') || '-'} />
        <CardRow label="Full Name" value={getFullName()} />
        <CardRow label="Date of Birth" value={formatDate(get(idCardTextData, 'birthday'))} />
        <CardRow label="Place of Birth" value={get(idCardTextData, 'place_of_birth') || '-'} />
        <CardRow label="Gender" value={get(idCardTextData, 'gender') || '-'} />
        <CardRow label="Address" value={get(idCardTextData, 'address') || '-'} />
      </Styled.KycCard>
      <Styled.KycActions>
        <Button wide variation="success" onClick={handleConfirm}>Confirm</Button>
      </Styled.KycActions>
    </div>
  );
};

KycStep2.propTypes = {
  idToken: PropTypes.string.isRequired,
};

export default KycStep2;
