import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import {
  DashboardCard,
  Page,
} from 'shared/components';
import { checkKycStatus } from 'shared/helpers';
import HeaderShape from 'shared/assets/header-shape.png';
import * as Styled from './styles';

const Dashboard = () => {
  const history = useHistory();
  const user = useSelector(state => get(state, 'account.user'));
  const userName = get(user, 'user_name');
  const kYCStatus = checkKycStatus(get(user, 'status'));

  return (
    <Page>
      <div className="Dashboard">
        <Styled.DashboardBackground style={{ backgroundImage: `url(${HeaderShape})` }} />
        <Styled.DashboardInner>
          <Styled.DashboardUserName>
            {userName ? `Hi ${userName}!` : 'Hey there!'}
          </Styled.DashboardUserName>
          {!kYCStatus.isValidated ? (
            <DashboardCard
              label="Verifications"
              icon="verify"
              title="Let's make sure it's you!"
              btnText="Get started"
              onBtnClick={() => history.push('/verifications')}
              text="Please complete the mandatory verification modules to be able to request your first loan."
            />
          ) : (
            <DashboardCard
              label="Verifications"
              icon="verify"
              title="You have been verified"
              onBtnClick={() => {}}
              text="Your identity verification has been successful"
            />
          )}
        </Styled.DashboardInner>
      </div>
    </Page>
  );
};

export default Dashboard;
