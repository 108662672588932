export default () => (
  <div className="IconIdentity">
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#EFFBF3" />
      <path d="M7.64 11.5C7.01039 11.5 6.5 12.0104 6.5 12.64V27.5138C6.5 28.1434 7.01039 28.6538 7.64 28.6538H33.36C33.9896 28.6538 34.5 28.1434 34.5 27.5138V12.64C34.5 12.0104 33.9896 11.5 33.36 11.5H7.64Z" fill="white" stroke="#22BE84" />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="6" y="11" width="29" height="19">
        <path d="M7.64 11.5C7.01039 11.5 6.5 12.0104 6.5 12.64V27.5138C6.5 28.1434 7.01039 28.6538 7.64 28.6538H33.36C33.9896 28.6538 34.5 28.1434 34.5 27.5138V12.64C34.5 12.0104 33.9896 11.5 33.36 11.5H7.64Z" fill="white" stroke="white" />
      </mask>
      <g mask="url(#mask0)">
        <path fillRule="evenodd" clipRule="evenodd" d="M11.6538 26.9184C11.6538 24.7976 13.373 23.0784 15.4938 23.0784H25.16C27.2807 23.0784 29 24.7976 29 26.9184V30.8781C29 32.9988 27.2807 34.7181 25.16 34.7181H15.4938C13.373 34.7181 11.6538 32.9988 11.6538 30.8781V26.9184Z" stroke="#22BE84" />
        <path fillRule="evenodd" clipRule="evenodd" d="M18.5385 22.188H22.4615V23.2008C22.4615 24.2841 21.5833 25.1623 20.5 25.1623C19.4167 25.1623 18.5385 24.2841 18.5385 23.2008V22.188Z" fill="#22BE84" stroke="#22BE84" strokeWidth="0.64" />
        <path d="M17.0769 17.1069H23.9231V19.2651C23.9231 21.1556 22.3905 22.6881 20.5 22.6881C18.6095 22.6881 17.0769 21.1556 17.0769 19.2651V17.1069Z" fill="white" stroke="#22BE84" />
        <path d="M25.0769 26.1367V36.6666H15.9231V26.1367V26.1367" stroke="#22BE84" />
        <rect x="12" y="28.1538" width="17" height="4.84615" fill="white" stroke="#22BE84" />
        <path fillRule="evenodd" clipRule="evenodd" d="M16.5371 16.7891C16.5371 14.9718 18.0104 13.4985 19.8277 13.4985H25.691V15.5091C25.691 16.2161 25.1179 16.7891 24.411 16.7891H16.5371Z" fill="#22BE84" />
      </g>
    </svg>
  </div>
);
