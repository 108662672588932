import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkKycStatus } from 'shared/helpers';
import { Page, VerificationCard } from 'shared/components';
import * as Styled from './styles';

const Verifications = () => {
  const history = useHistory();
  const user = useSelector(state => get(state, 'account.user'));
  const kycStatus = checkKycStatus(get(user, 'status'));

  return (
    <Page>
      <Styled.Verifications>
        <VerificationCard
          type="kyc"
          title="Proof of identity"
          description="You must verify your identity before you can apply for a loan"
          onCardClick={() => {
            if (kycStatus.isValidated) return null;
            history.push('/verifications/kyc');
            return true;
          }}
          status={kycStatus.isValidated ? 'success' : null}
        />
        <VerificationCard
          type="income"
          title="Proof of income"
          description="You must verify your income before you can apply for a loan"
          isLocked
          onCardClick={() => { }}
        />
        <VerificationCard
          type="character"
          title="Proof of character"
          description="Complete your proof of character to receive extra credit"
          isLocked
          onCardClick={() => { }}
        />
        <VerificationCard
          type="social"
          title="Social media"
          description="Connect your social media profile to receive extra credit"
          isLocked
          onCardClick={() => { }}
        />
      </Styled.Verifications>
    </Page>
  );
};

export default Verifications;
