import {
  Page,
} from 'shared/components';

const Support = () => (
  <Page>
    <div className="Support">
      <div>Support and FAQ</div>
      <div>Here goes contact form</div>
    </div>
  </Page>
);
export default Support;
