import * as Styled from './styles';

const FaceOverlay = () => (
  <Styled.Overlay>
    <svg viewBox="0 0 334 441" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="2" y="2" width="330" height="438">
        <path fillRule="evenodd" clipRule="evenodd" d="M2 2V440H332V2H2Z" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <path fillRule="evenodd" clipRule="evenodd" d="M167 251.893C203.451 251.893 233 211.321 233 161.273C233 111.224 203.451 70.6519 167 70.6519C130.549 70.6519 101 111.224 101 161.273C101 211.321 130.549 251.893 167 251.893Z" stroke="white" strokeWidth="4" strokeLinejoin="round" />
        <path fillRule="evenodd" clipRule="evenodd" d="M167 582.796C303.69 582.796 414.5 516.405 414.5 434.508C414.5 352.61 303.69 286.219 167 286.219C30.3095 286.219 -80.5 352.61 -80.5 434.508C-80.5 516.405 30.3095 582.796 167 582.796Z" stroke="white" strokeWidth="4" strokeLinejoin="round" />
      </g>
      <path d="M58 2H6C3.79086 2 2 3.79086 2 6V70" stroke="white" strokeWidth="4" strokeLinejoin="round" />
      <path d="M276 2H328C330.209 2 332 3.79086 332 6V70" stroke="white" strokeWidth="4" strokeLinejoin="round" />
      <path d="M58 439H6C3.79086 439 2 437.209 2 435V371" stroke="white" strokeWidth="4" strokeLinejoin="round" />
      <path d="M276 439H328C330.209 439 332 437.209 332 435V371" stroke="white" strokeWidth="4" strokeLinejoin="round" />
    </svg>
  </Styled.Overlay>
);

export default FaceOverlay;
