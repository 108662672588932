import styled from 'styled-components';

export const VerificationCard = styled.div`
  background-color: #fff;
  border-radius: ${props => props.theme.borderRadius};
  padding: 15px;
  box-shadow: 0px 3px 6px rgba(210, 222, 235, 0.4);
  max-width: 600px;
  display: flex;
  margin-bottom: 15px;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent !important;
  min-height: 85px;
  opacity: ${props => {
    if (props.isLocked) { return '0.6'; }
    return '1';
  }};
  pointer-events: ${props => {
    if (props.isLocked) { return 'none'; }
    return 'auto';
  }};

  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    justify-content: space-between;
  }
`;

export const CardType = styled.div`
  flex-shrink: 0;
  min-width: 40px;
  align-self: flex-start;

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
  }
`;

export const CardBody = styled.div`
  flex-shrink: 0;
  min-width: 180px;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: 80px;

  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    margin: 0;
  }
`;

export const CardTitle = styled.div`
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 5px;

  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    max-width: 180px;
  }
`;

export const CardDesc = styled.div`
  font-size: 12px;
  color: ${props => props.theme.colors.gray};

  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    max-width: 180px;
  }
`;

export const CardStatus = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  color: ${props => {
    if (props.status === 'success') {
      return props.theme.colors.success;
    }
    if (props.status === 'fail') {
      return props.theme.colors.error;
    }
    return props.theme.colors.textPrimary;
  }};
`;

export const CardActions = styled.div`
  flex-shrink: 0;
  align-self: center;

  .ContentLoader {
    padding: 0;
    
    &-spinner {
      width: 28px;
      height: 28px;
    }
  }
`;
