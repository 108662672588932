import PropTypes from 'prop-types';
import { get } from 'lodash';
import { formatDate } from 'shared/helpers';
import CardRow from '../CardRow';
import * as Styled from './styles';

const ProfileCard = ({
  user,
}) => {
  const kycStatus = get(user, 'status');

  return (
    <Styled.ProfileCard>
      <CardRow label="Username" value={get(user, 'user_name')} />
      <CardRow label="Is validated" value={kycStatus} />
      <CardRow label="First name" value={get(user, 'profile.first_name')} />
      <CardRow label="Last name" value={get(user, 'profile.last_name')} />
      <CardRow label="Birthday" value={formatDate(get(user, 'profile.birthday'))} />
      <CardRow label="National ID number" value={get(user, 'profile.national_id_nr')} />
    </Styled.ProfileCard>
  );
};

ProfileCard.propTypes = {
  user: PropTypes.object.isRequired,
};

export default ProfileCard;
