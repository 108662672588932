export default () => (
  <div className="IconSupport">
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <rect x="2" y="1" width="24" height="24" rx="12" fill="white" />
      </g>
      <path fillRule="evenodd" clipRule="evenodd" d="M8 9C8 8.44772 8.44772 8 9 8H19C19.5523 8 20 8.44772 20 9V17C20 17.5523 19.5523 18 19 18H12L9.6 19.8C8.94076 20.2944 8 19.824 8 19L8 9Z" stroke="#57677C" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.68 14.136V13.696C13.68 13.5787 13.6867 13.4787 13.7 13.396C13.7134 13.3133 13.7374 13.2387 13.772 13.172C13.8067 13.1053 13.852 13.0413 13.908 12.98C13.964 12.9187 14.032 12.8453 14.112 12.76L14.728 12.136C14.8614 12.0027 14.928 11.8293 14.928 11.616C14.928 11.408 14.86 11.2387 14.724 11.108C14.588 10.9773 14.416 10.912 14.208 10.912C13.984 10.912 13.8 10.988 13.656 11.14C13.512 11.292 13.4294 11.4773 13.408 11.696L12.384 11.616C12.416 11.36 12.4827 11.132 12.584 10.932C12.6854 10.732 12.8174 10.5627 12.98 10.424C13.1427 10.2853 13.3307 10.18 13.544 10.108C13.7574 10.036 13.992 10 14.248 10C14.488 10 14.7107 10.0347 14.916 10.104C15.1214 10.1733 15.3 10.2733 15.452 10.404C15.604 10.5347 15.7227 10.6973 15.808 10.892C15.8934 11.0867 15.936 11.3093 15.936 11.56C15.936 11.736 15.912 11.8907 15.864 12.024C15.816 12.1573 15.752 12.28 15.672 12.392C15.592 12.504 15.5 12.6107 15.396 12.712C15.292 12.8133 15.1814 12.92 15.064 13.032C14.9894 13.1013 14.9254 13.1627 14.872 13.216C14.8187 13.2693 14.7747 13.324 14.74 13.38C14.7054 13.436 14.68 13.5 14.664 13.572C14.648 13.644 14.64 13.7307 14.64 13.832V14.136H13.68ZM13.52 15.248C13.52 15.0773 13.5827 14.9307 13.708 14.808C13.8334 14.6853 13.984 14.624 14.16 14.624C14.3307 14.624 14.48 14.6827 14.608 14.8C14.736 14.9173 14.8 15.0613 14.8 15.232C14.8 15.4027 14.7374 15.5493 14.612 15.672C14.4867 15.7947 14.336 15.856 14.16 15.856C14.0747 15.856 13.9934 15.84 13.916 15.808C13.8387 15.776 13.7707 15.7333 13.712 15.68C13.6534 15.6267 13.6067 15.5627 13.572 15.488C13.5374 15.4133 13.52 15.3333 13.52 15.248Z" fill="#57677C" />
      <defs>
        <filter id="filter0_d" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.823529 0 0 0 0 0.870588 0 0 0 0 0.921569 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>

  </div>
);
