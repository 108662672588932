import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, sortBy } from 'lodash';
import api from 'shared/api';
import { ContentLoader } from 'shared/components';
import * as Styled from './styles';

const ProfileHeading = ({
  user,
}) => {
  const userID = get(user, 'profile.user_id');

  const [selfieImages, setSelfieImages] = useState([]);
  const [imagesLoading, setImagesLoading] = useState(true);

  const setProfileFiles = async (files) => {
    const selfies = files.filter(f => get(f, 'type_name') === 'selfie');
    const sortedSelfies = sortBy(selfies, (f) => new Date(get(f, 'file.created_at'))).reverse();
    const latestSelfieID = get(sortedSelfies, '[0].file.id');
    await api.get(
      `/api/user-management/files/${latestSelfieID}/content`,
      { responseType: 'blob' },
    ).then((res) => {
      setSelfieImages([{
        ...sortedSelfies[0],
        img: URL.createObjectURL(get(res, 'data')),
      }]);
    });
    setImagesLoading(false);
  };

  useEffect(() => {
    api.get(`/api/user-management/users/${userID}/files`)
      .then((res) => {
        const files = get(res, 'data') || [];
        if (files.length) {
          setProfileFiles(files);
        } else {
          setSelfieImages(null);
          setImagesLoading(false);
        }
      })
      .catch(() => {
        setImagesLoading(false);
      });
  }, [userID]);

  return (
    <Styled.ProfileHeading>
      <Styled.ImageWrapper>
        {imagesLoading ? (
          <Styled.ImageLoading><ContentLoader color="#eaeaea" /></Styled.ImageLoading>
        ) : (
          <Styled.Image>
            {selfieImages ? <img src={get(selfieImages, '[0].img')} alt="profile selfie" /> : null}
          </Styled.Image>
        )}
      </Styled.ImageWrapper>
      <Styled.Username>{get(user, 'user_name') || '-'}</Styled.Username>
    </Styled.ProfileHeading>
  );
};

ProfileHeading.propTypes = {
  user: PropTypes.object.isRequired,
};

export default ProfileHeading;
