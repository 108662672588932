import styled from 'styled-components';

export const ProgressBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  max-width: 220px;
  margin: 10px auto 20px;
`;

export const ProgressBarItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-weight: 600;
  background: ${props => {
    if (props.active) {
      return '#fff';
    }
    return props.theme.form.inputDisabledBackground;
  }};
  color: ${props => {
    if (props.active) {
      return props.theme.colors.green;
    }
    return '#fff';
  }};
`;
