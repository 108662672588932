import PropTypes from 'prop-types';
import { IconCameraUpload, ContentLoader } from 'shared/components';
import {
  IconCharacter,
  IconFailed,
  IconIdentity,
  IconIncome,
  IconLocked,
  IconSocial,
  IconStart,
  IconSuccess,
} from './icons';
import * as Styled from './styles';

const VerificationCard = ({
  title,
  type,
  status,
  description,
  isLocked,
  isLoading,
  onCardClick,
  image,
}) => {
  const getIcon = () => {
    if (image) {
      return <img src={image} alt="card" />;
    }

    switch (type) {
      case 'kyc':
        return <IconIdentity />;
      case 'income':
        return <IconIncome />;
      case 'character':
        return <IconCharacter />;
      case 'social':
        return <IconSocial />;
      case 'camera':
        return <IconCameraUpload />;
      case 'image':
        return 'img';
      default:
        return null;
    }
  };

  const getActionIcon = () => {
    if (isLocked) {
      return <IconLocked />;
    }
    if (isLoading) {
      return <ContentLoader color="#22BE84" />;
    }
    if (status === 'success') {
      return <IconSuccess />;
    }
    if (status === 'fail') {
      return <IconFailed />;
    }
    return <IconStart />;
  };

  return (
    <Styled.VerificationCard
      isLocked={isLocked}
      role="button"
      tabIndex={0}
      onClick={onCardClick}
    >
      <Styled.CardType>{getIcon()}</Styled.CardType>
      <Styled.CardBody>
        <Styled.CardTitle isLocked={isLocked}>{title}</Styled.CardTitle>
        <Styled.CardDesc isLocked={isLocked}>
          {status ? (
            <Styled.CardStatus status={status}>{status}</Styled.CardStatus>
          ) : (
            <span>{description}</span>
          )}
        </Styled.CardDesc>
      </Styled.CardBody>
      <Styled.CardActions>{getActionIcon()}</Styled.CardActions>
    </Styled.VerificationCard>
  );
};

VerificationCard.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  image: PropTypes.string,
  status: PropTypes.string,
  description: PropTypes.string.isRequired,
  onCardClick: PropTypes.func.isRequired,
  isLocked: PropTypes.bool,
  isLoading: PropTypes.bool,
};

VerificationCard.defaultProps = {
  isLocked: false,
  isLoading: false,
  status: null,
  image: null,
};

export default VerificationCard;
