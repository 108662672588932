/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';

const getIDCameraPreviewStyles = () => css`
  display: flex;
  min-height: 62vh;
  align-items: center;
  justify-content: center;
  padding: 20px;

  video {
    min-height: 200px !important;
    max-height: 200px !important;
    padding: 5px;
    border: 2px solid #fff;
    border-radius: 10px;
  }
`;

const getIDImagePreviewStyles = () => css`
  display: flex;
  min-height: 62vh;
  align-items: center;
  justify-content: center;
  padding: 20px;

  img {
    min-height: 200px !important;
    max-height: 200px !important;
    width: 100%;
    padding: 5px;
    border: 2px solid #fff;
    border-radius: 10px;
    object-fit: contain;
  }
`;

export const ModalInner = styled.div`
  min-height: 100vh;
  width: 100vw;
  max-width: 600px;
  background: #000;
  display: flex;
  flex-direction: column;
`;

export const ImagePreview = styled.div`
  img {
    min-height: 66vh;
    max-height: 400px;
    max-width: 600px;
    width: 100%;

    @media screen and (max-width: 350px) {
      min-height: 350px;
      max-height: 350px;
    }
  }

  ${props => {
    if (props.isIDVerification) {
      return getIDImagePreviewStyles();
    }
  }}
`;

export const CameraPreview = styled.div`
  position: relative;

  div {
    position: relative;

    video {
      min-height: 66vh;
      max-height: 400px;
      max-width: 600px;

      @media screen and (max-width: 350px) {
        min-height: 350px;
        max-height: 350px;
      }
    }
  }

  ${props => {
    if (props.isIDVerification) {
      return getIDCameraPreviewStyles();
    }
  }}
`;

export const ActionBtns = styled.div`
  padding: 20px 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Btn = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

export const SubmitBtn = styled.div`
  padding: 30px 20px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;

  button {
    width: 100%;
    margin: 0;
  }

  @media screen and (max-width: 350px) {
    padding-top: 20px;
  }
`;

export const CloseBtn = styled.div`
  position: absolute;
  top: 0;
  right: 5px;

  button {
    padding: 0;
  }
`;
