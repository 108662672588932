import { Route, Switch } from 'react-router-dom';
import Header from 'Header';
import Dashboard from 'Dashboard';
import Verifications from 'Verifications';
import Terms from 'Terms';
import Support from 'Support';
import Profile from 'Profile';
import Settings from 'Settings';
import KycVerification from 'Verifications/KycVerification';
import { NotFound } from 'shared/components';
// import { IsVerified } from 'shared/HOCs';
// const Test = () => <div className="Test">test</div>;

const AppContainer = () => (
  <div className="AppContainer">
    <Header />
    <Switch>
      <Route exact path="/verifications" component={Verifications} />
      <Route path="/verifications/kyc" component={KycVerification} />
      {/* <Route exact path="/loans" render={() => <div>loan history</div>} />
      <Route path="/loans/:loanID" render={() => <div>loan details</div>} /> */}
      <Route path="/settings" component={Settings} />
      <Route path="/terms" component={Terms} />
      <Route path="/support" component={Support} />
      <Route path="/profile" component={Profile} />
      {/* <Route path="/test" component={IsVerified(Test)} /> */}
      <Route exact path="/" component={Dashboard} />
      <Route path="*" component={NotFound} />
    </Switch>
  </div>
);

export default AppContainer;
