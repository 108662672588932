import styled from 'styled-components';

export const Overlay = styled.div`
  position: absolute !important;
  right: 10%;

  svg {
    transform: rotate(-90deg);
    opacity: 0.75;
  }
`;
