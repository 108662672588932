import { createContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SidebarMenu from 'SidebarMenu';
import * as Styled from './styles';

const Header = () => {
  const location = useLocation();
  const MenuContext = createContext();

  const [menuOpenState, setMenuOpenState] = useState(false);

  const getTitle = () => {
    const { pathname } = location;

    switch (pathname) {
      case '/':
        return 'Dashboard';
      case '/verifications':
        return 'Verifications';
      case '/verifications/kyc':
        return 'Identity verification';
      case '/loans':
        return 'Loan history';
      case '/settings':
        return 'Settings';
      case '/terms':
        return 'Terms and Conditions';
      case '/support':
        return 'Support and FAQ';
      case '/profile':
        return 'Profile';
      default:
        return '';
    }
  };

  const contextValue = {
    isMenuOpen: menuOpenState,
    toggleMenu: () => setMenuOpenState(!menuOpenState),
    stateChangeHandler: (newState) => setMenuOpenState(newState.isOpen),
  };

  return (
    <MenuContext.Provider value={contextValue}>
      <Styled.Header>
        <Styled.HeaderLeft>
          <SidebarMenu context={MenuContext} />
          <Styled.HeaderPageTitle>{getTitle()}</Styled.HeaderPageTitle>
        </Styled.HeaderLeft>
        <Styled.HeaderRight />
      </Styled.Header>
    </MenuContext.Provider>
  );
};

export default Header;
