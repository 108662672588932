import styled from 'styled-components';

export const KycCard = styled.div`
  background: #fff;
  box-shadow: 0px 3px 6px rgba(210, 222, 235, 0.4);
  border-radius: ${props => props.theme.borderRadius};
  padding: 20px;
`;

export const CardHeading = styled.div`
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 30px;
  text-transform: uppercase;
`;

export const KycActions = styled.div`
  margin-top: 30px;
`;
