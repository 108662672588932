import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import api from 'shared/api';
import {
  Button,
  ContentLoader,
  Page,
} from 'shared/components';
import {
  ChangePasswordForm,
  ProfileCard,
  ProfileHeading,
} from './components';
import * as Styled from './styles';

const Profile = () => {
  const user = useSelector(state => get(state, 'account.user'));
  const userID = get(user, 'user_id');

  const [isLoading, setLoading] = useState(true);
  const [profileData, setProfileData] = useState(null);
  const [showPasswordForm, setPasswordFormDisplay] = useState(false);

  useEffect(() => {
    api.get(`/api/user-management/user-profiles/${userID}`)
      .then((res) => {
        setProfileData(get(res, 'data'));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [userID]);

  if (isLoading) {
    return <Page><ContentLoader /></Page>;
  }

  if (!profileData) {
    return <Page><div>No data</div></Page>;
  }

  return (
    <Page>
      <div className="Profile">
        <ProfileHeading user={get(profileData, 'user')} />
        <ProfileCard user={get(profileData, 'user')} />
        <Styled.ProfileActions>
          <Button
            wide
            onClick={() => setPasswordFormDisplay(true)}
          >
            Change password
          </Button>
        </Styled.ProfileActions>
        {showPasswordForm && (
          <ChangePasswordForm
            isOpen={showPasswordForm}
            closeCb={() => setPasswordFormDisplay(false)}
            userID={userID}
          />
        )}
      </div>
    </Page>
  );
};

export default Profile;
