import PropTypes from 'prop-types';
import { InputErrorMessage } from 'shared/components';
import * as Styled from './styles';

const RadioBtn = ({
  disabled,
  error,
  inputId,
  value,
  name,
  label,
  handleChange,
  ...rest
}) => {
  const handleInputChange = e => handleChange(e.target.value, e);

  return (
    <Styled.RadioBtn>
      <Styled.RadioBtnLabel htmlFor={inputId} disabled={disabled} hasError={!!error}>
        <Styled.RadioBtnInput
          type="radio"
          name={name}
          id={inputId}
          value={value}
          onChange={handleInputChange}
          hasError={!!error}
          disabled={disabled}
          {...rest}
        />
        <span>
          {label}
        </span>
      </Styled.RadioBtnLabel>
      <InputErrorMessage text={error} />
    </Styled.RadioBtn>
  );
};

RadioBtn.propTypes = {
  inputId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func,
  disabled: PropTypes.bool,
};

RadioBtn.defaultProps = {
  disabled: false,
  label: '',
  error: '',
  handleChange: () => {},
};

export default RadioBtn;
