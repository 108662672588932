import styled from 'styled-components';

export const DashboardCard = styled.div`
  background-color: #fff;
  border-radius: ${props => props.theme.borderRadius};
  padding: 15px;
  box-shadow: 0px 3px 6px rgba(210, 222, 235, 0.4);
  max-width: 800px;
  display: flex;
  flex-direction: column;
`;

export const CardLabel = styled.div`
  color: ${props => props.theme.colors.success};
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  margin-bottom: 10px;
`;

export const CardIcon = styled.div`
  margin: 10px 0 20px;
`;

export const CardTitle = styled.div`
  margin-bottom: 20px;
  font-weight: 600;
  color: ${props => props.theme.colors.textPrimary};
  font-size: 16px;
`;

export const CardBody = styled.div`
  color: #8998AC;
  font-size: 14px;

  @media screen and (max-width: ${props => props.theme.breakpoints.xxs}) {
    max-width: 290px;
  }
`;

export const CardActions = styled.div`
  margin-top: 20px;

  button {
    width: 100%;
    max-width: 400px;
  }
`;
