import styled from 'styled-components';

export const Notice = styled.div`
  display: flex;
  align-items: center;
  padding: 18px;
  border-radius: ${props => props.theme.borderRadius};
  box-shadow: 0 3px 6px 0 rgba(210, 222, 235, 0.4);
  background-color: #fff;
`;

export const NoticeIcon = styled.div`
  flex-shrink: 0;
  margin-right: 20px;
`;

export const NoticeTitle = styled.div`
  font-weight: 600;
  margin-bottom: 6px;
`;

export const NoticeDesc = styled.div`
  font-size: 14px;
`;
