import * as Styled from './styles';

const IdOverlay = () => (
  <Styled.Overlay>
    <svg width="105" height="65" viewBox="0 0 165 123" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.799642">
        <path fillRule="evenodd" clipRule="evenodd" d="M2.50342 12.5C2.50342 6.97715 6.98057 2.5 12.5034 2.5H152.491C158.014 2.5 162.491 6.97715 162.491 12.5V110.5C162.491 116.023 158.014 120.5 152.491 120.5H12.5034C6.98057 120.5 2.50342 116.023 2.50342 110.5L2.50342 12.5Z" stroke="white" strokeWidth="4" />
        <path fillRule="evenodd" clipRule="evenodd" d="M41.2708 61.5C41.2708 80.0015 61.6664 95 86.8256 95C111.985 95 132.38 80.0015 132.38 61.5C132.38 42.9985 111.985 28 86.8256 28C61.6664 28 41.2708 42.9985 41.2708 61.5Z" stroke="white" strokeWidth="4" strokeLinejoin="round" />
        <path d="M13.1479 2C13.1479 2 29.6567 31.4209 29.6567 61C29.6567 90.5791 14.1542 120 14.1542 120" stroke="white" strokeWidth="4" />
      </g>
    </svg>
  </Styled.Overlay>
);

export default IdOverlay;
