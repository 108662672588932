import { useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import {
  IconHome,
  IconSettings,
  IconClose,
  IconSidebarMenu,
  // IconLoanHistory,
  IconSupport,
  IconTerms,
  IconUser,
  IconDashboardVerify,
} from 'shared/components';
import version from 'version';
import * as Styled from './styles';
import './styles.css';

const SidebarMenu = ({
  context,
}) => {
  const ctx = useContext(context);
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;

  return (
    <Menu
      customBurgerIcon={<IconSidebarMenu />}
      customCrossIcon={<IconClose />}
      disableAutoFocus
      isOpen={ctx.isMenuOpen}
      onStateChange={(state) => ctx.stateChangeHandler(state)}
    >
      <Styled.SidebarMenu>
        <Styled.MenuHeading>MENU</Styled.MenuHeading>
        <Styled.MenuList>
          <Styled.MenuListItem
            type="button"
            active={pathname === '/'}
            onClick={() => {
              ctx.toggleMenu();
              history.push('/');
            }}
          >
            <IconHome />
            <Styled.ItemText>Dashboard</Styled.ItemText>
          </Styled.MenuListItem>
          <Styled.MenuListItem
            type="button"
            active={pathname.includes('verifications')}
            onClick={() => {
              ctx.toggleMenu();
              history.push('/verifications');
            }}
          >
            <IconDashboardVerify height="16" width="24" />
            <Styled.ItemText>Verifications</Styled.ItemText>
          </Styled.MenuListItem>
          {/* <Styled.MenuListItem
            active={pathname.includes('loans')}
            type="button"
            onClick={() => {
              ctx.toggleMenu();
              history.push('/loans');
            }}
          >
            <IconLoanHistory />
            <Styled.ItemText>Loans history</Styled.ItemText>
          </Styled.MenuListItem> */}
          <Styled.MenuListItem
            active={pathname.includes('settings')}
            type="button"
            onClick={() => {
              ctx.toggleMenu();
              history.push('/settings');
            }}
          >
            <IconSettings />
            <Styled.ItemText>Settings</Styled.ItemText>
          </Styled.MenuListItem>
          <Styled.MenuListItem
            active={pathname.includes('terms')}
            type="button"
            onClick={() => {
              ctx.toggleMenu();
              history.push('/terms');
            }}
          >
            <IconTerms />
            <Styled.ItemText>Terms and Conditions</Styled.ItemText>
          </Styled.MenuListItem>
          <Styled.MenuListItem
            active={pathname.includes('support')}
            type="button"
            onClick={() => {
              ctx.toggleMenu();
              history.push('/support');
            }}
          >
            <IconSupport />
            <Styled.ItemText>Support / FAQ</Styled.ItemText>
          </Styled.MenuListItem>
          <Styled.MenuListItem
            active={pathname.includes('profile')}
            type="button"
            onClick={() => {
              ctx.toggleMenu();
              history.push('/profile');
            }}
          >
            <IconUser fill="none" stroke="#555" strokeWidth={4} height="16px" />
            <Styled.ItemText>Profile</Styled.ItemText>
          </Styled.MenuListItem>
        </Styled.MenuList>
        <Styled.MenuFooter>© All rights reserved.</Styled.MenuFooter>
        <Styled.MenuVersion>{`client app ${version}`}</Styled.MenuVersion>
      </Styled.SidebarMenu>
    </Menu>
  );
};

SidebarMenu.propTypes = {
  context: PropTypes.object.isRequired,
};

export default SidebarMenu;
