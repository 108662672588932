import PropTypes from 'prop-types';
import * as Styled from './styles';

const IconWarning = () => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none">
    <path d="M21 42C9.35455 42 0 32.6455 0 21C0 9.35455 9.35455 0 21 0C32.6455 0 42 9.35455 42 21C42 32.6455 32.6455 42 21 42ZM21 3.81818C11.4545 3.81818 3.81818 11.4545 3.81818 21C3.81818 30.5455 11.4545 38.1818 21 38.1818C30.5455 38.1818 38.1818 30.5455 38.1818 21C38.1818 11.4545 30.5455 3.81818 21 3.81818Z" fill="#FBBC05" />
    <path d="M21 22.9091C19.8545 22.9091 19.0909 22.1455 19.0909 21V13.3636C19.0909 12.2182 19.8545 11.4545 21 11.4545C22.1455 11.4545 22.9091 12.2182 22.9091 13.3636V21C22.9091 22.1455 22.1455 22.9091 21 22.9091Z" fill="#FBBC05" />
    <path d="M21 30.5455C20.4273 30.5455 20.0455 30.3545 19.6636 29.9727C19.2818 29.5909 19.0909 29.2091 19.0909 28.6364C19.0909 28.4455 19.0909 28.0636 19.2818 27.8727C19.4727 27.6818 19.4727 27.4909 19.6636 27.3C20.2364 26.7273 21 26.5364 21.7636 26.9182C21.9545 26.9182 21.9545 26.9182 22.1455 27.1091C22.1455 27.1091 22.3364 27.3 22.5273 27.3C22.7182 27.4909 22.9091 27.6818 22.9091 27.8727C22.9091 28.0636 22.9091 28.4455 22.9091 28.6364C22.9091 28.8273 22.9091 29.2091 22.7182 29.4C22.5273 29.5909 22.5273 29.7818 22.3364 29.9727C21.9545 30.3545 21.5727 30.5455 21 30.5455Z" fill="#FBBC05" />
  </svg>
);

const IconError = () => (
  <svg width="43" height="38" viewBox="0 0 43 38" fill="none">
    <path d="M5.60346 38C4.66385 38 3.72424 37.8109 2.78463 37.2438C0.153718 35.7313 -0.785894 32.1393 0.717485 29.4925L16.6909 2.64677C17.2546 1.89055 17.8184 1.13433 18.5701 0.756219C19.8856 -5.96046e-08 21.3889 -0.189055 22.8923 0.189055C24.3957 0.567164 25.5232 1.51244 26.4628 2.83582L42.2483 29.4925C42.8121 30.4378 43 31.3831 43 32.3284C43 33.8408 42.4362 35.3532 41.3087 36.2985C40.3691 37.4328 39.0536 38 37.5503 38H5.60346ZM19.8856 4.72637L4.10009 31.3831C3.53632 32.3284 3.91216 33.4627 4.85178 34.0299C5.0397 34.2189 5.41554 34.2189 5.60346 34.2189H37.3623C37.9261 34.2189 38.3019 34.0299 38.6778 33.6517C39.0536 33.2736 39.2416 32.8955 39.2416 32.3284C39.2416 31.9502 39.2416 31.7612 39.0536 31.3831L23.2682 4.72637C22.7044 3.78109 21.5769 3.59204 20.6372 3.97015C20.2614 4.1592 20.0735 4.34826 19.8856 4.72637Z" fill="#F13535" />
    <path d="M21.5769 22.8756C20.4493 22.8756 19.6976 22.1194 19.6976 20.9851V13.4229C19.6976 12.2886 20.4493 11.5323 21.5769 11.5323C22.7044 11.5323 23.4561 12.2886 23.4561 13.4229V20.9851C23.4561 22.1194 22.7044 22.8756 21.5769 22.8756Z" fill="#F13535" />
    <path d="M21.5769 30.4378C21.0131 30.4378 20.6372 30.2488 20.2614 29.8706C19.8856 29.4925 19.6976 29.1144 19.6976 28.5473C19.6976 28.3582 19.6976 27.9801 19.8856 27.791C20.0735 27.602 20.0735 27.4129 20.2614 27.2239C20.4493 27.0348 20.6372 26.8458 20.8252 26.8458C21.201 26.6567 21.5769 26.6567 21.9527 26.6567C22.1406 26.6567 22.1406 26.6567 22.3285 26.8458C22.5165 26.8458 22.5165 26.8458 22.7044 27.0348C22.7044 27.0348 22.8923 27.2239 23.0802 27.2239C23.2682 27.4129 23.4561 27.602 23.4561 27.791C23.4561 27.9801 23.644 28.3582 23.644 28.5473C23.644 29.1144 23.4561 29.4925 23.0802 29.8706C22.5165 30.2488 22.1406 30.4378 21.5769 30.4378Z" fill="#F13535" />
  </svg>
);

const getIcon = (type) => {
  switch (type) {
    case 'info':
      return 'info';
    case 'warning':
      return <IconWarning />;
    case 'success':
      return 'success';
    case 'error':
      return <IconError />;
    default:
      return null;
  }
};

const Notice = ({
  description,
  title,
  type,
}) => (
  <Styled.Notice>
    <Styled.NoticeIcon>{getIcon(type)}</Styled.NoticeIcon>
    <div className="body">
      {title && <Styled.NoticeTitle>{title}</Styled.NoticeTitle>}
      {description && <Styled.NoticeDesc>{description}</Styled.NoticeDesc>}
    </div>
  </Styled.Notice>
);

Notice.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(['info', 'warning', 'success', 'error']).isRequired,
};

Notice.defaultProps = {
  description: '',
  title: '',
};

export default Notice;
