import PropTypes from 'prop-types';
import * as Styled from './styles';

const ProgressBar = ({
  activeStep,
  numOfSteps,
}) => {
  const createItems = () => {
    const items = [];
    for (let i = 1; i <= numOfSteps; i++) {
      const item = (
        <Styled.ProgressBarItem key={i} active={activeStep === i}>{i}</Styled.ProgressBarItem>
      );
      items.push(item);
    }
    return items;
  };

  return (
    <Styled.ProgressBar>{createItems()}</Styled.ProgressBar>
  );
};

ProgressBar.propTypes = {
  activeStep: PropTypes.number.isRequired,
  numOfSteps: PropTypes.number.isRequired,
};

export default ProgressBar;
