export default () => (
  <div className="IconIncome">
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="#EFFBF3" />
      <path d="M7.5 14C7.5 13.7239 7.72386 13.5 8 13.5H32C32.2761 13.5 32.5 13.7239 32.5 14V28C32.5 28.2761 32.2761 28.5 32 28.5H8C7.72386 28.5 7.5 28.2761 7.5 28V14Z" fill="white" stroke="#22BE84" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20 27C23.3137 27 26 24.3137 26 21C26 17.6863 23.3137 15 20 15C16.6863 15 14 17.6863 14 21C14 24.3137 16.6863 27 20 27Z" fill="#22BE84" />
      <path fillRule="evenodd" clipRule="evenodd" d="M20.2367 17.6575C20.5361 17.6575 20.8286 17.7139 21.1143 17.8265C21.4 17.9391 21.6463 18.1172 21.8531 18.3607L21.2653 19C21.1401 18.8234 20.9891 18.6849 20.8122 18.5845C20.6354 18.484 20.4435 18.4307 20.2367 18.4247V20.5799L20.2204 20.589C20.4544 20.6621 20.6789 20.7428 20.8939 20.8311C21.1088 20.9193 21.298 21.032 21.4612 21.1689C21.6245 21.3059 21.7551 21.4779 21.8531 21.6849C21.951 21.8919 22 22.1507 22 22.4612C22 22.7412 21.9524 22.9939 21.8571 23.2192C21.7619 23.4444 21.634 23.6377 21.4735 23.7991C21.3129 23.9604 21.1252 24.0868 20.9102 24.1781C20.6952 24.2694 20.4707 24.3242 20.2367 24.3425V25H19.7959V24.3425C19.4367 24.3425 19.1034 24.277 18.7959 24.1461C18.4884 24.0152 18.2231 23.7884 18 23.4658L18.6286 22.8356C18.7537 23.067 18.9224 23.2451 19.1347 23.3699C19.3469 23.4947 19.5673 23.5632 19.7959 23.5753V21.3288C19.5728 21.2679 19.3619 21.1903 19.1633 21.0959C18.9646 21.0015 18.7878 20.8813 18.6327 20.7352C18.4776 20.589 18.3551 20.4125 18.2653 20.2055C18.1755 19.9985 18.1306 19.7489 18.1306 19.4566C18.1306 19.1948 18.1769 18.9589 18.2694 18.7489C18.3619 18.5388 18.4844 18.3562 18.6367 18.2009C18.7891 18.0457 18.966 17.9209 19.1673 17.8265C19.3687 17.7321 19.5782 17.6758 19.7959 17.6575V17H20.2367V17.6575ZM19.8816 18C19.6204 18.0487 19.4082 18.1598 19.2449 18.3333C19.0816 18.5069 19 18.7397 19 19.032C19 19.1781 19.0245 19.3044 19.0735 19.411C19.1224 19.5175 19.1878 19.6103 19.2694 19.6895C19.351 19.7686 19.4449 19.8356 19.551 19.8904C19.6571 19.9452 19.7673 19.9909 19.8816 20.0274V18ZM20 23.1187C20.1361 23.1005 20.2626 23.0639 20.3796 23.0091C20.4966 22.9543 20.6 22.8813 20.6898 22.79C20.7796 22.6986 20.8503 22.5906 20.902 22.4658C20.9537 22.3409 20.9796 22.1994 20.9796 22.0411C20.9796 21.8828 20.951 21.7473 20.8939 21.6347C20.8367 21.5221 20.7633 21.4262 20.6735 21.347C20.5837 21.2679 20.4789 21.2009 20.3592 21.1461C20.2395 21.0913 20.1197 21.0426 20 21V23.1187Z" fill="white" />
      <path d="M32.5 17.4725C30.4142 17.242 28.758 15.5858 28.5275 13.5H32C32.2761 13.5 32.5 13.7239 32.5 14V17.4725Z" stroke="#22BE84" />
      <path d="M28.5275 28.5C28.758 26.4142 30.4142 24.758 32.5 24.5275V28C32.5 28.2761 32.2761 28.5 32 28.5H28.5275Z" stroke="#22BE84" />
      <path d="M7.5 14C7.5 13.7239 7.72386 13.5 8 13.5H11.4725C11.242 15.5858 9.58581 17.242 7.5 17.4725V14Z" stroke="#22BE84" />
      <path d="M7.5 24.5275C9.58581 24.758 11.242 26.4142 11.4725 28.5H8C7.72386 28.5 7.5 28.2761 7.5 28V24.5275Z" stroke="#22BE84" />
    </svg>
  </div>
);
