import PropTypes from 'prop-types';
import { Button, IconDashboardVerify } from 'shared/components';
import * as Styled from './styles';

const IconLoan = () => (
  <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M28.4907 0.164089C30.7404 -0.47867 33.0852 0.823979 33.7279 3.07364C33.8361 3.45212 33.8909 3.84383 33.8909 4.23746V7.78391H38.1273C40.467 7.78391 42.3636 9.68059 42.3636 12.0203V37.4385C42.3636 39.7781 40.467 41.6748 38.1273 41.6748H4.23636C1.89669 41.6748 0 39.7781 0 37.4385H0.056569C0.0189636 37.2107 0 36.9799 0 36.7485V11.4998C0 9.60834 1.25387 7.94605 3.07255 7.42643L28.4907 0.164089ZM17.242 37.4385H38.1273V20.493H33.8909V29.4861C33.8909 31.3776 32.6371 33.0399 30.8184 33.5595L17.242 37.4385ZM38.1273 12.0203V16.2566H33.8909V12.0203H38.1273ZM4.23642 11.4997V36.7484L29.6546 29.4861V4.2374L4.23642 11.4997ZM25.4182 16.2566C25.4182 17.4265 24.4698 18.3748 23.3 18.3748C22.1302 18.3748 21.1818 17.4265 21.1818 16.2566C21.1818 15.0868 22.1302 14.1385 23.3 14.1385C24.4698 14.1385 25.4182 15.0868 25.4182 16.2566Z" fill="#006F6C" />
  </svg>
);

const getIcon = (icon) => {
  if (icon === 'verify') {
    return <IconDashboardVerify height="34" width="50" />;
  }
  if (icon === 'loan') {
    return <IconLoan />;
  }
  return null;
};

const DashboardCard = ({
  label,
  icon,
  title,
  text,
  btnText,
  onBtnClick,
}) => (
  <Styled.DashboardCard>
    <Styled.CardLabel>{label}</Styled.CardLabel>
    {icon && <Styled.CardIcon>{getIcon(icon)}</Styled.CardIcon>}
    <Styled.CardTitle>{title}</Styled.CardTitle>
    <Styled.CardBody>{text}</Styled.CardBody>
    <Styled.CardActions>
      {btnText && (
        <Button
          variation="success"
          onClick={onBtnClick}
        >
          {btnText}
        </Button>
      )}
    </Styled.CardActions>
  </Styled.DashboardCard>
);

DashboardCard.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  btnText: PropTypes.string,
  onBtnClick: PropTypes.func.isRequired,
};

DashboardCard.defaultProps = {
  btnText: null,
  icon: null,
  text: '',
};

export default DashboardCard;
