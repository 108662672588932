import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'shared/components';
import icon from './404_page_not_found.svg';
import * as Styled from './styles';

const NotFound = () => {
  const [t] = useTranslation();
  const history = useHistory();
  return (
    <Styled.NotFound>
      <Styled.NotFoundImage src={icon} alt="Not found" />
      <Styled.NotFoundText>{t('We can not seem to find the page you are looking for')}</Styled.NotFoundText>
      <Button
        onClick={() => {
          history.push('/');
        }}
      >
        {t('Go back to homepage')}
      </Button>
    </Styled.NotFound>
  );
};

export default NotFound;
