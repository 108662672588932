import { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import {
  Button,
  CameraModal,
  ContentLoader,
  VerificationCard,
} from 'shared/components';
import api from 'shared/api';
import * as Styled from './styles';

const KycStep1 = ({
  handleNext,
}) => {
  const user = useSelector(state => get(state, 'account.user'));
  const userID = get(user, 'user_id');

  const [cameraOpened, setCameraOpen] = useState(false);
  const [cameraType, setCameraType] = useState('selfie');
  const [selfieLoading, setSelfieLoading] = useState(false);
  const [selfiePhoto, setSelfiePhoto] = useState(null);
  const [selfieToken, setSelfieToken] = useState(null);
  const [idLoading, setIdLoading] = useState(false);
  const [idPhoto, setIdPhoto] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [verificationLoading, setVerificationLoading] = useState(false);

  const setFormData = async (image, type) => {
    const data = new FormData();
    await fetch(image)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], 'image');
        data.append('image', file);
        data.append('type_', type);
        data.append('user_id', userID);
      });
    return data;
  };

  const sendImage = async (image, type) => {
    const data = await setFormData(image, type);
    api.post('/api/kyc/upload-file', data)
      .then((res) => {
        const key = get(res, 'data');
        // get key from response
        // set it as token -> use type to find out which token
        if (type === 'selfie') {
          setSelfiePhoto(image);
          setSelfieLoading(false);
          setSelfieToken(key);
        } else {
          setIdPhoto(image);
          setIdLoading(false);
          setIdToken(key);
        }
      })
      .catch(() => {
        toast.error('Image upload has failed');
        setSelfieLoading(false);
        setIdLoading(false);
      });
  };

  const setImage = async (image) => {
    if (cameraType === 'selfie') {
      setSelfieLoading(true);
      await sendImage(image, 'selfie');
    } else {
      setIdLoading(true);
      await sendImage(image, 'id_card');
    }
  };

  const handleSubmit = () => {
    setVerificationLoading(true);
    const data = { user_id: userID, selfie_key: selfieToken, id_card_key: idToken };

    api.post('/api/kyc/compare-faces', data)
      .then(() => {
        handleNext(idToken);
      })
      .catch(() => {
        toast.error('KYC face compare has failed');
        setVerificationLoading(false);
      });
  };

  return (
    <div className="KycStep1">
      <VerificationCard
        title="KTP"
        type="camera"
        image={idPhoto}
        status={idPhoto ? 'success' : null}
        description="Take a picture of your identity card"
        onCardClick={() => {
          setCameraType('id');
          setIdPhoto(null);
          setIdToken(null);
          setCameraOpen(true);
        }}
        isLoading={idLoading}
      />
      <VerificationCard
        title="Selfie"
        type="camera"
        image={selfiePhoto}
        status={selfiePhoto ? 'success' : null}
        description="Take a selfie to prove your identity"
        onCardClick={() => {
          setCameraType('selfie');
          setSelfiePhoto(null);
          setSelfieToken(null);
          setCameraOpen(true);
        }}
        isLoading={selfieLoading}
      />
      {verificationLoading && (
        <Styled.VerificationLoader>
          <ContentLoader color="#22BE84" text="We are verifying your uploads. This might take a few minutes." />
        </Styled.VerificationLoader>
      )}
      <Styled.KycActions>
        <Button
          disabled={!(selfieToken && idToken) || verificationLoading}
          variation="success"
          onClick={handleSubmit}
          wide
        >
          Next
        </Button>
      </Styled.KycActions>
      {cameraOpened && (
        <CameraModal
          closeCb={() => setCameraOpen(false)}
          isIDVerification={cameraType === 'id'}
          submitImage={(image) => setImage(image)}
        />
      )}
    </div>
  );
};

KycStep1.propTypes = {
  handleNext: PropTypes.func.isRequired,
};

export default KycStep1;
