import PropTypes from 'prop-types';

const IconClose = ({
  color,
}) => (
  <div className="IconClose">
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.07107 8.48528L1.41421 14.1421L0 12.7279L5.65685 7.07107L0 1.41421L1.41421 0L7.07107 5.65685L12.7279 0L14.1421 1.41421L8.48528 7.07107L14.1421 12.7279L12.7279 14.1421L7.07107 8.48528Z" fill={color} />
    </svg>
  </div>
);

IconClose.propTypes = {
  color: PropTypes.string,
};

IconClose.defaultProps = {
  color: '#3B414B',
};

export default IconClose;
