import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import { Camera } from 'react-camera-pro';
import { useTranslation } from 'react-i18next';
import { Button, IconClose } from 'shared/components';
import TakePhotoIcon from './take-photo.svg';
import RetryPhotoIcon from './retry-photo.svg';
import ChangeCameraIcon from './change-camera.svg';
import {
  FaceOverlay,
  IdOverlay,
} from './components';
import * as Styled from './styles';

ReactModal.setAppElement('#root');

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    top: '0',
    bottom: '0',
    right: '0',
    left: '0',
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: '99',
    overflowY: 'auto',
    boxSizing: 'border-box',
  },
  content: {
    position: 'relative',
    outline: 'none',
  },
};

const CameraModal = ({
  closeCb,
  submitImage,
  isIDVerification,
}) => {
  const [t] = useTranslation();
  const camera = useRef(null);
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [image, setImage] = useState(null);

  const handleImageSubmit = () => {
    submitImage(image);
    closeCb();
  };

  return (
    <ReactModal
      isOpen
      style={styles}
      onRequestClose={() => { }}
      shouldCloseOnOverlayClick={false}
      closeTimeoutMS={150}
      className="CameraModal"
    >
      <Styled.ModalInner>
        {image ? (
          <Styled.ImagePreview isIDVerification={isIDVerification}>
            <img src={image} alt="Taken selfie" />
          </Styled.ImagePreview>
        ) : (
          <Styled.CameraPreview isIDVerification={isIDVerification}>
            <Camera
              ref={camera}
              aspectRatio="cover"
              numberOfCamerasCallback={setNumberOfCameras}
              facingMode={isIDVerification ? 'environment' : 'user'}
            />
            {isIDVerification ? <IdOverlay /> : <FaceOverlay />}
          </Styled.CameraPreview>
        )}
        <Styled.ActionBtns>
          <Styled.Btn className="retry" type="button" onClick={() => setImage(null)}>
            <img src={RetryPhotoIcon} alt="retry" />
          </Styled.Btn>
          <Styled.Btn
            className="take"
            type="button"
            onClick={() => {
              if (camera.current) {
                const img = camera.current.takePhoto();
                setImage(img);
              }
            }}
          >
            <img src={TakePhotoIcon} alt="take" />
          </Styled.Btn>
          <Styled.Btn
            className="change"
            type="button"
            disabled={numberOfCameras <= 1}
            onClick={() => {
              if (camera.current) {
                camera.current.switchCamera();
              }
            }}
          >
            <img src={ChangeCameraIcon} alt="change camera" />
          </Styled.Btn>
        </Styled.ActionBtns>
        <Styled.SubmitBtn>
          <Button
            variation="success"
            size="lg"
            onClick={handleImageSubmit}
            disabled={!image}
          >
            {t('Submit')}
          </Button>
        </Styled.SubmitBtn>
        <Styled.CloseBtn>
          <Button
            variation="link"
            size="sm"
            onClick={closeCb}
          >
            <IconClose color="#fff" />
          </Button>
        </Styled.CloseBtn>
      </Styled.ModalInner>
    </ReactModal>
  );
};

CameraModal.propTypes = {
  closeCb: PropTypes.func.isRequired,
  submitImage: PropTypes.func.isRequired,
  isIDVerification: PropTypes.bool,
};

CameraModal.defaultProps = {
  isIDVerification: false,
};

export default CameraModal;
