import { useState, useEffect } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import toast from 'react-hot-toast';
import {
  Button,
  PasswordInput,
} from 'shared/components';
import {
  invitation,
  invitationError,
  invitationSuccess,
} from 'redux/account/actions';
import * as Styled from './styles';

const getUUID = (params) => {
  const value = params.split('?')[1].split('&')[0].split('=')[1];
  return value;
};

const InvitationContainer = () => {
  const history = useHistory();
  const location = useLocation();
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const currentUser = useSelector(state => get(state, 'account.user'));
  const currentUserID = get(currentUser, 'user_id');

  const [isLoading, setLoading] = useState(false);
  const [password, setPassword] = useState('');

  const { search } = location;
  const hasUUID = search.includes('uuid');
  const uuid = hasUUID ? getUUID(search) : '';

  useEffect(() => {
    if (currentUserID) {
      history.push('/');
    }
    if (!hasUUID) {
      history.push('/login');
    }
  }, [currentUserID, history]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (isLoading) {
      return false;
    }

    const invitationData = {
      password,
    };
    setLoading(true);

    invitation(uuid, invitationData)
      .then((res) => {
        const userData = get(res, 'data');
        dispatch(invitationSuccess(userData));
        history.push('/');
      })
      .catch((err) => {
        setLoading(false);
        dispatch(invitationError(err));
        toast.error('Unable to finish invitation process');
      });
    return true;
  };

  return (
    <Styled.Invitation>
      <Styled.FormContainer>
        <form
          className="register-password-form"
          id="register-password-form"
          onSubmit={handleFormSubmit}
        >
          <fieldset disabled={isLoading}>
            <div className="register-password-view">
              <Styled.FormHeading>
                <h1>{t('Create an account')}</h1>
              </Styled.FormHeading>
              <Styled.FormRow>
                <PasswordInput
                  disabled={isLoading}
                  handleChange={val => setPassword(val)}
                  id="login-pass"
                  value={password}
                  placeholder={t('Password')}
                />
              </Styled.FormRow>
              <Styled.FormRow>
                <Button
                  disabled={isLoading}
                  variation="success"
                  type="submit"
                  onClick={handleFormSubmit}
                >
                  {t('Sign up')}
                </Button>
              </Styled.FormRow>
            </div>
            <Styled.LoginReroute>
              <span>{t('Already have an account?')}</span>
              <NavLink to="/login">{t('Login')}</NavLink>
            </Styled.LoginReroute>
          </fieldset>
        </form>
      </Styled.FormContainer>
    </Styled.Invitation>
  );
};

export default InvitationContainer;
