import {
  Page,
} from 'shared/components';

const Terms = () => (
  <Page>
    <div className="Terms">
      Terms and conditions
    </div>
  </Page>
);
export default Terms;
