import styled from 'styled-components';

export const Login = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  background-color: #f5f6f6;
  background-size: cover;
  background: linear-gradient(0deg, rgba(210,234,239,1) 0%, rgba(241,250,246,1) 100%);
`;

export const FormContainer = styled.div`
  padding: 20px 50px;
  position: relative;
  border-radius: ${props => props.theme.borderRadius};

  @media screen and (max-width: ${props => props.theme.breakpoints.xs}) {
    padding: 20px 0;
    width: 100%;
  }

  fieldset {
    border: 0;

    &:disabled {
      opacity: 0.5;
    }
  }
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;

  .TextInput,
  .PasswordInput {
    max-width: unset;
    width: 100%;

    input {
      max-width: unset;
      width: 100%;
    }
  }
`;

export const FormHeading = styled(FormRow)`

  h1 {
    font-size: 18px;
    margin: 10px 0 20px;
    text-align: center;
    color: ${props => props.theme.colors.green};
    text-transform: uppercase;
  }
`;
