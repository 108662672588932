import styled from 'styled-components';

export const VerificationLoader = styled.div`
  .ContentLoader {
      padding: 20px 0;

      &-text {
        font-size: 12px;
        max-width: 235px;
        margin: 10px auto 0;
        color: ${props => props.theme.colors.textSecondary};
      }
    }
`;

export const KycActions = styled.div`
  margin-top: 40px;
`;
