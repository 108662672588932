import { useState } from 'react';
import {
  Page, ProgressBar,
} from 'shared/components';
import KycStep1 from './KycStep1';
import KycStep2 from './KycStep2';
import * as Styled from './styles';

const Verifications = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [step1Data, setStep1Data] = useState(null);

  return (
    <Page>
      <div className="KycVerification">
        <Styled.KycProgress>
          <ProgressBar activeStep={activeStep} numOfSteps={2} />
        </Styled.KycProgress>
        {activeStep === 1 && (
          <KycStep1
            handleNext={(data) => {
              setStep1Data(data);
              setActiveStep(2);
            }}
          />
        )}
        {activeStep === 2 && <KycStep2 idToken={step1Data} />}
      </div>
    </Page>
  );
};

export default Verifications;
