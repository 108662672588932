import styled from 'styled-components';

export const DashboardBackground = styled.div`
    width: 100%;
    height: 280px;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
`;

export const DashboardInner = styled.div`
  position: relative;
`;

export const DashboardUserName = styled.div`
    font-weight: 600;
    font-size: 20px;
    margin: 12px 0 20px 15px;
    color: #fff;
    word-wrap: break-word;
`;
