export default () => (
  <div className="IconTerms">
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d)">
        <rect x="2" y="1" width="24" height="24" rx="12" fill="white" />
      </g>
      <rect x="8" y="7" width="12" height="12" rx="1" stroke="#57677C" />
      <path d="M11 11H17" stroke="#57677C" strokeLinecap="round" />
      <path d="M11 14H14" stroke="#57677C" strokeLinecap="round" />
      <defs>
        <filter id="filter0_d" x="0" y="0" width="28" height="28" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.823529 0 0 0 0 0.870588 0 0 0 0 0.921569 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
      </defs>
    </svg>

  </div>
);
